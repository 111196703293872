:root {
  --brand500: #731012;
  --brand300: #c49b9b;
  --brand200: rgba(244, 236, 236, 1);
}

.banner_hero {
  background-image: url('/public/images/bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 55vh;
  min-height: 400px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.react-select-container {
  min-width: 180px;
  flex: 1;
  color: black;
  font-size: 14px;
}

.react-select__control,
.react-select__control:hover {
  border-color: none;
}

.trending__like {
  position: absolute;
  right: 0;
  top: 0;
  padding: 7px;
  transform: translate(-50%, 20%);
  border: 2px thin var(--brand500);
  transition: all 0.3s ease-in;
  cursor: pointer;
}
.trending__like:hover {
  scale: 1.1;
  background-color: var(--brand300);
  color: white;
}

.why__choose__us__card {
  background-color: white;
  /* min-width: 300px; */
  height: 325px;
  padding: 35px;
  /* padding-bottom: 20px; */
  transition: all 0.2s ease-in;
}
.why__choose__us__card:hover {
  background-color: var(--brand500);
  color: white;
}

.why__choose__us__icon {
  height: 60px;
  width: 50px;
  background-color: var(--brand500);
  border-radius: 5px;
  color: white;
}

.why__choose__us__card:hover .why__choose__us__icon {
  color: var(--brand500);
  background-color: white;
}

.why__choose__us__card:hover .card__head {
  color: white;
}

.car__by__model_contianer:hover :last-child {
  transition: all 0.2s ease-in;
  background-color: var(--brand300);
  color: var(--brand500);
}

.vehicle__filter__settings {
  font-size: 18px;
  justify-content: space-between;
}
.vehicle__filter__settings svg {
  transform: rotate(90deg);
}

.settings__type__card {
  transition: all 0.5s ease-in;
  padding: 25px 20px;
  border-radius: 4px;
  background-color: white;
  gap: 1rem;
  overflow: hidden;
}
.settings__type__card:hover {
  box-shadow: 1px 1px 10px 1px #c9c9c9;
}

.settings__type__card .arrow {
  position: relative;
  right: -10%;
  opacity: 0;
  transition: all 0.8s ease-in-out;
}
.settings__type__card:hover .arrow {
  right: 0;
  opacity: 1;
}
.settings__type__card:hover svg {
  color: var(--brand500);
}

.pagination_buttons_container {
  display: flex;
  margin: 2rem 0;
  gap: 3px;
  list-style-type: none;
  font-size: 17px;
}
.pagination_buttons_container li {
  width: 45px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ededed;
  border-radius: 5px;
}
.pagination_buttons_container li a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination_buttons_container li:hover {
  background-color: var(--brand200);
}

.pagination__count__break {
  font-size: 18px;
}

.pagination_buttons_container .active__page {
  background-color: var(--brand500);
  color: white;
}

.pagination_buttons_container .active__page:hover {
  background-color: var(--brand500);
}

.remove__uploaded__photo {
  background-color: var(--brand200);
  padding: 6px 6px;
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
}

.listing-active-tab {
  color: var(--brand500);
  background-color: var(--brand200);
}

.shape {
  position: absolute;
  z-index: 1;
}
.shape-blue {
  top: 0;
  left: -80px;
  height: 150px;
  /* transform: translate(-200%, 100%) !important; */
  /* transform: rotate(270deg); */
  /* z-index: 0 !important; */
}

/* @media (max-width: 640px) {
  .react-select-container {
    width: 100%;
  }
} */
.fav-slide {
  width: 400px;
}

.more__images {
  position: absolute;
  padding: 10px;
  right: 0;
  top: 0;
  transform: translate(0, 10vh);
  border: 1px solid var(--brand500);
  z-index: 10;
  background: #fff;
  border-radius: 5px;
  font-weight: 600;
}

.car-img-with-dots {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, 40%);
  z-index: -1;
}
